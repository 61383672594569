import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "./PopularSearchModule.scss"
import CTALink from "@components/elements/CTALink"

const UsefulLInks = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSideBar {
        nodes {
          useful_links {
            ...ctaFragment
          }
        }
      }
    }
  `)


  return (
    data?.allStrapiSideBar?.nodes?.length > 0 && data?.allStrapiSideBar?.nodes[0]?.useful_links?.length > 0 && 
    <div className="alldepartments-popular-search">
      <div className="popular_links_holder">
        <div className="default-psearch-wrapper psearch">
          <h4>Useful Links</h4>
          <div className="column-links">
              {data?.allStrapiSideBar?.nodes[0]?.useful_links.map((item, i) => {
                return (<div><CTALink
                  key={item.id}
                  cta={item}
                  className="sub-menu-link"
                >
                  {item?.cta_label}
                </CTALink></div>)
              })}

          </div>

        </div>

      </div>
    </div>
  )
}

export default UsefulLInks
