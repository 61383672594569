import { TeamContactModal } from "@components/TeamContactModal/TeamContactModal"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import { bookAViewingURL, teamsURL } from "@lib/urls"
import { addRollWhatsapp, getWhatsAppURL, getWhatsBaseURL, gtagReportConversion } from "@lib/utils"
import { Link } from "gatsby"
import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import "./PropertyNegoCard.scss"
import { PhoneIcon } from "lucide-react"
// import BookaViewingFormMini from "@components/forms/BookaViewingFormMini"
import WhatsAppIconWhite from "@components/icons/WhatsAppIconWhite"
import WhatsAppIconGreen from "@components/icons/WhatsAppIconGreen"
import WhatsAppIconWB from "@components/icons/WhatsAppIconWB"
import loadable from "@loadable/component"
// import {  SaveItem, useUserObjectState, isAuthenticated } from "@starberry/myaccount-website-utils"
import useDeviceMedia from "@hooks/useDeviceMedia"
import PhoneIconPC from "@components/icons/PhoneIconPC"
import GGFXImage from "@components/elements/GGFXImage"
import { useGlobalStore } from "@stores/globalStore"
import { seoConData } from "@components/SearchResults/SearchFilters/filterOptions"
const BookaViewingFormMini = loadable(() => import("@components/forms/BookaViewingFormMini"))
const PropertyNegoCardSR = ({
  team_data
}) => {
  const { leadSource } = useGlobalStore()
  let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)
  return (
    <div className="property-nego-card-wrap sr">
      <h4>Connect with Our Property Experts Today!</h4>
      <div className="top-section">
        <div className="bottom-section">
          <Link to={`${teamsURL + team_data[0]?.slug}/`} className="img-section img-zoom">
            {team_data[0]?.image?.url ?
              <GGFXImage
                key={team_data[0]?.strapi_id}
                ImageSrc={team_data[0]?.image}
                altText={team_data[0]?.name}
                imagetransforms={team_data[0]?.ggfx_results}
                renderer="srcSet"
                imagename="team.image.team_img_mini"
                strapiID={team_data[0]?.strapi_id}
              /> : team_data[0]?.extra?.profile_image ?
                <img src={team_data[0]?.extra?.profile_image} alt={`${team_data[0]?.name} - ${process.env.GATSBY_SITE_NAME}`} />
                : null}
          </Link>
          <div className="nego-info">
            <Link to={`${teamsURL + team_data[0]?.slug}/`}>
              <p className="name">{team_data[0]?.name}</p>
              <p className="designation">{team_data[0]?.designation}</p>
              {team_data[0]?.negotiator_id && <p className="orn-no">BRN No: {team_data[0]?.negotiator_id}</p>}
            </Link>
          </div>
         

        </div>
        
      </div>
      <div className="cta-section">
            {team_data[0]?.phone &&
              <a href={`tel:${team_data[0]?.phone}`} onClick={() => gtagReportConversion(`tel:${team_data[0]?.phone}`)} className="property-cta">
                <PhoneIconPC />
                <span>Phone</span>
              </a>}

            {team_data && team_data?.length > 0 && team_data[0]?.phone && <a target="_blank"
              href={`https://whatsapp-yecmsd26ra-uc.a.run.app?phone=971505389860&email=${team_data[0]?.email}&resp_phone=${team_data[0]?.phone?.replace(/\D/g, "")}&text=${encodeURIComponent(`Hello Provident,\n\nI would like to contact ${team_data[0]?.name}\n\n• Email: ${team_data[0]?.email}\n• Link: ${process.env.GATSBY_SITE_URL + teamsURL + team_data[0]?.slug}\n• ID: ${team_data[0]?.negotiator_mapping}\n\nModifying this message will prevent it from being sent to the agent.`)}&resp_name=${encodeURIComponent(team_data[0]?.name)}&utm_source=${encodeURIComponent(seoSourceName && seoSourceName?.length > 0 && seoSourceName[0]?.name)}`}
              className="property-cta whats"
              onClick={() => addRollWhatsapp()}
            >
              <WhatsAppIconGreen outline green />
              <span>WhatsApp</span>
            </a>}
          </div>
    </div>
  )
}

export default PropertyNegoCardSR
