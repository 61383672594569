import OffplanCard from "@components/OffplanCard/OffplanCard"
import PropertyCard from "@components/PropertyCard/PropertyCard"
import OffMarketForm from "@components/forms/OffMarketForm"
import useLocation from "@hooks/useLocation"
import { addRollWhatsapp, getWhatsAppURL, gtagReportConversion, slugToTitle } from "@lib/utils"
import qs from "qs"
import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { isEmpty } from "lodash"
import AdsBanner from "@components/AdsBanner/AdsBanner"
import { usePropertyStore } from "../store/propertyStore"
import "./NoResults.scss"
import useTeams from "@hooks/dataHooks/useTeams"
import PhoneIcon from "@components/icons/PhoneIcon"
import { teamsURL } from "@lib/urls"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import GGFXImage from "@components/elements/GGFXImage"
import { Link } from "gatsby"
import MailIcon from "@components/icons/MailIcon"
import { seoConData } from "../SearchFilters/filterOptions"
import { useGlobalStore } from "@stores/globalStore"
import { getUserobjectData, isAuthenticated } from "@starberry/myaccount-website-utils"
import TeamContactModalWithTrigger from "@components/TeamContactModal/TeamContactModal"

const NoResults = ({ selectedAreas, filteredData, searchResult }) => {
  const [properties, setProperties] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [userObjects, setUserObjects] = useState({});
  useEffect(() => {
    if (isAuthenticated()) {
      const getUserObjects = async () => {
        try {
          const userObjects = await getUserobjectData()
          setUserObjects(userObjects.data.data)
        } catch (e) {
          
        }
      }
      getUserObjects()
    }
  }, [])
  const {
    // searchResult,
    pType,
    pDepartment,
    buildingType,
    developer,
    // initialised,
  } = usePropertyStore((state) => ({
    // searchResult: state.searchResult,
    pType: state.pType,
    pDepartment: state.pDepartment,
    buildingType: state.buildingType,
    developer: state.developer,
    // initialised: state.initialised,
  }))




  // if(filteredData && filteredData?.length > 0 && filteredData[0]?.email){
  //   whatsappURL = getWhatsAppURL(
  //     "971505389860",
  //     `Hello Provident,\n\nI would like to contact ${filteredData[0]?.name}\n\n• Email: ${filteredData[0]?.email}\n• Link: ${process.env.GATSBY_SITE_URL + teamsURL + filteredData[0]?.slug}\n• ID: ${filteredData[0]?.negotiator_mapping}\n\nModifying this message will prevent it from being sent to the agent.`,
  //     filteredData[0]?.email,
  //     filteredData[0]?.phone,
  //     filteredData[0]?.name
  //   ) 
  // }

  // Calling the function
  const { pathname } = useLocation()

  const isOffPlan = pDepartment === "new_developments"

  const { nbHits, nbPages, page } = searchResult

  React.useEffect(() => {
    if (nbPages > page) return

    let query = null
    if (nbHits === 0) {
      if (isOffPlan) {
        query = qs.stringify(
          {
            filters: {
              publish: {
                $eq: true,
              },
              developer: {
                $containsi: slugToTitle(developer?.value),
              },
            },
            pagination: {
              pageSize: 6,
            },
            populate: "*",
          },
          {
            encodeValuesOnly: true, // prettify URL
          }
        )
      } else {
        query = qs.stringify(
          {
            filters: {
              department: {
                $eq: pDepartment,
              },
              search_type: {
                $eq: pType,
              },
              publish: {
                $eq: true,
              },
              building: {
                $containsi: slugToTitle(buildingType?.value),
              },
            },
            pagination: {
              pageSize: 6,
            },
            populate: "*",
          },
          {
            encodeValuesOnly: true, // prettify URL
          }
        )
      }

      const url = `${process.env.GATSBY_STRAPI_SRC}api/${isOffPlan ? "off-plans" : "properties"
        }?${query}`

      const token = process.env.GATSBY_STRAPI_FORM_TOKEN

      setLoading(true)
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then(({ data }) => {
          setProperties(data)
          setLoading(false)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error("🚀CustomNoHits.js useEffect", err)
          setLoading(false)
        })
    }
  }, [nbHits, pathname])
  const { leadSource } = useGlobalStore()
  let seoSourceName = seoConData.filter((item) => item.id.toString() === leadSource)
  const Image = ({ ImageSrc, altText, imagetransforms, imagename, strapiID }) => {
    return (
      <GGFXImage
        ImageSrc={ImageSrc}
        altText={altText}
        imagetransforms={imagetransforms}
        renderer="srcSet"
        imagename={imagename}
        strapiID={strapiID}
      />
    )
  }
 
  const getOrderedImages = (offplan) => {
    // Initialize an empty array to store the ordered images
    let imagesArray = [];
  
    if (offplan?.attributes?.tile_image?.data?.attributes) {
      // If tile_image exists, check media_images
      if (offplan?.attributes?.media_images?.length > 0) {
        imagesArray = [offplan?.attributes?.tile_image, ...offplan?.attributes?.media_images];
      } else if (offplan?.attributes?.images?.length > 0) {
        // If media_images is not available, use images array with tile_image first
        imagesArray = [offplan?.attributes?.tile_image?.data?.attributes, ...offplan?.attributes?.images];
      } else {
        // If neither media_images nor images are available, only use tile_image
        imagesArray = [offplan?.attributes?.tile_image?.data?.attributes];
      }
    } else if (offplan?.attributes?.media_images?.length > 0) {
      // If tile_image is not available, use media_images if it exists
      imagesArray = [...offplan?.attributes?.media_images];
    } else if (offplan?.attributes?.images?.length > 0) {
      // If neither tile_image nor media_images are available, use images
      imagesArray = [...offplan?.attributes?.images];
    }
  
    return imagesArray;
  };

  return (
    <div className="property-list-container properties-no-results">
        <>
          <Container className="off-market-form-container">
            <div className="off-market-form-wrap">
              <h2>Register for Off Market Properties</h2>
              <OffMarketForm submitText="Register Now" />
            </div>
            {filteredData && filteredData?.length > 0 &&
              <div className="team-community-card">
                <h3>Contact Property Expert</h3>
                <div className="image-wrap">
                  {filteredData[0]?.image?.url ?
                    <GGFXImage
                      key={filteredData[0]?.strapi_id}
                      ImageSrc={filteredData[0]?.image}
                      altText={filteredData[0]?.name}
                      imagetransforms={filteredData[0]?.ggfx_results}
                      renderer="srcSet"
                      imagename="team.image.team_img"
                      strapiID={filteredData[0]?.strapi_id}
                    /> : filteredData[0]?.extra?.profile_image ?
                      <img onContextMenu={(e) => e.preventDefault()} draggable="false" src={filteredData[0]?.extra?.profile_image} alt={`${filteredData[0]?.name} - ${process.env.GATSBY_SITE_NAME}`} />
                      : null}
                </div>
                <div className="name">{filteredData[0]?.name}</div>
                <div className="desination">{filteredData[0]?.designation}</div>
                <div className="brn-no">BRN No: {filteredData[0]?.negotiator_id}</div>
                <div className="cta-section">
                 

                  <TeamContactModalWithTrigger
                    team={filteredData && filteredData?.length > 0 && filteredData[0]}
                    className="button button-orange"
                  >
                    <MailIcon color="white" />
                    <span>Email</span>

                  </TeamContactModalWithTrigger>
                  {filteredData[0]?.phone &&
                    <a href={`tel:${filteredData[0]?.phone}`} onClick={() => gtagReportConversion(`tel:${filteredData[0]?.phone}`)} className="button button-blue d-none d-md-flex">
                      <PhoneIcon color={"#fff"} />
                      <span>Call</span>
                    </a>}

                  {filteredData && filteredData?.length > 0 && filteredData[0]?.phone && <a target="_blank"
                    href={`https://whatsapp-yecmsd26ra-uc.a.run.app?phone=971505389860&email=${filteredData[0]?.email}&resp_phone=${filteredData[0]?.phone?.replace(/\D/g, "")}&text=${encodeURIComponent(`Hello Provident,\n\nI would like to contact ${filteredData[0]?.name}\n\n• Email: ${filteredData[0]?.email}\n• Link: ${process.env.GATSBY_SITE_URL + teamsURL + filteredData[0]?.slug}\n• ID: ${filteredData[0]?.negotiator_mapping}\n\nModifying this message will prevent it from being sent to the agent.`)}&resp_name=${encodeURIComponent(filteredData[0]?.name)}&utm_source=${encodeURIComponent(seoSourceName && seoSourceName?.length > 0 && seoSourceName[0]?.name)}`}
                    className="button button-green"
                    onClick={() => addRollWhatsapp()}
                  >
                    <WhatsAppIcon outline2 />
                    <span>WhatsApp</span>
                  </a>}
                </div>
              </div>}
          </Container>
          {!(!loading && isEmpty(properties)) && (
            <div className="property-list-section-wrap">
              {loading || isEmpty(properties) ? (
                <Container className="pb-4">
                  Loading Recommended Properties...
                </Container>
              ) : (
                <Container className="property-list-section">
                  <h3>Recommended Properties</h3>
                  {properties.map((property, index) => {
                      const imagesArray = isOffPlan ? getOrderedImages(property) : [];
                   return (
                    <React.Fragment key={`${property?.attributes?.slug}card`}>
                      {isOffPlan ? (
                        <OffplanCard
                          future_launch={property?.attributes?.future_launch}
                          minsBeds={property?.attributes?.min_bedrooms}
                          maxBeds={property?.attributes?.max_bedrooms}
                          displayBedrooms={
                            property?.attributes?.display_bedrooms
                          }
                          buldingType={property?.attributes?.building_type}
                          image={
                            property?.attributes?.tile_image?.data?.attributes ? property?.attributes?.tile_image?.data?.attributes :
                              property?.attributes?.media_images?.data?.length > 0 ? property?.attributes?.media_images?.data[0] : property?.attributes?.images?.length > 0 ? property?.attributes?.images[0] : ""
                          }
                          images={
                            !isEmpty(property?.attributes?.images) ?
                              property?.attributes?.images?.map(
                                (image) => image
                              ) :
                              !isEmpty(property?.attributes?.media_images?.data) ?
                                property?.attributes?.media_images?.data?.map(
                                  (image) => image?.attributes
                                ) : []
                          }
                          images2={imagesArray}
                          title={property?.attributes?.title}
                          description={property?.attributes?.about}
                          developer={property?.attributes?.developer}
                          location={property?.attributes?.display_address}
                          slug={property?.attributes?.slug}
                          videoURL={
                            property?.attributes?.video_module?.video_url
                          }
                          community={
                            property?.attributes?.community?.data?.attributes
                              ?.title
                          }
                          completionYear={property?.attributes?.completion_year}
                          key={`${property?.attributes?.slug}offplan`}
                          price={property?.attributes?.price}
                          ggfx_results={
                            property?.attributes?.ggfx_results
                          }
                          strapi_id={property?.id}
                          listView
                          notalgo
                          property={property?.attributes}
                        />
                      ) : (
                        <PropertyCard
                          key={property?.attributes?.crm_id}
                          address={property?.attributes?.display_address}
                          description={property?.attributes?.description}
                          bathrooms={property?.attributes?.bathroom}
                          email={property?.attributes?.crm_negotiator_id?.email}
                          crm_negotiator_id={property?.attributes?.crm_negotiator_id?.id}
                          bedrooms={property?.attributes?.bedroom}
                          building={property?.attributes?.building?.[0]}
                          image={property?.attributes?.images?.[0]}
                          images={property.attributes.images}
                          price={property?.attributes?.price}
                          size={property?.attributes?.floorarea_min}
                          crmID={property?.attributes?.crm_id}
                          bitrix_id={property?.attributes?.extra?.bitrix_id}
                          slug={property?.attributes?.slug}
                          phone={property?.attributes?.crm_negotiator_id?.phone}
                          name={property?.attributes?.crm_negotiator_id?.name}
                          searchType={property?.attributes?.search_type}
                          imagetransforms={
                            property?.attributes?.ggfx_results
                          }
                          longDescription={
                            property?.attributes?.long_description
                          }
                          strapiID={`${property?.id}`}
                          listView
                          department={property?.attributes?.department}
                          //   index={index}
                          singnatureProp={property?.attributes?.search_type === "sales" && property?.attributes?.price >= 20000000 && property?.attributes?.department === "residential" ? true : false}
                          userObjects={userObjects}
                          link_to_employee={property?.attributes?.link_to_employee}
                          imageCount={property?.images?.length}
                        />
                      )}
                      <AdsBanner
                        cardBanner
                        propertyIndex={index + 3}
                        listView
                      />
                    </React.Fragment>
                    )
                  })}
                </Container>
              )}
            </div>
          )}
        </>
      
    </div>
  )
}

export default NoResults