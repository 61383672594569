import { Link } from "gatsby"
import React from "react"
import "./PopularSearchModule.scss"
import { capitalize } from "lodash"
import { generatePropertyURL, offplanResultsURL, propertiesRentURL, propertiesSaleURL } from "@lib/urls"
import { slugify } from "@lib/utils"

const ResidentialPopularSearch = ({ dynamicPageData }) => {

  const area = capitalize(dynamicPageData?.area?.replace(/-/g, " "))
  const status =
    dynamicPageData?.searchType === "sales" ? "for sale" : dynamicPageData?.department==="Offplan"?"for sale":"to rent"

  const urlStatus =
    dynamicPageData?.searchType === "sales" ? "for-sale":dynamicPageData?.department==="Offplan"?"for-sale" : "to-rent"

  const propertyText =
    dynamicPageData?.department === "residential" ? "Properties" 
    : dynamicPageData?.department==="commercial"?"Commercial Properties":
    dynamicPageData?.department==="new_developments"?"off plan":""

    const isOffplan=dynamicPageData?.department==="new_developments";

  const bedCount = dynamicPageData?.bedroom==="0"?"Studio":dynamicPageData?.bedroom;
  const pType = capitalize(dynamicPageData?.propertyType)

  const propertyUrl= dynamicPageData?.department === "residential" && dynamicPageData?.searchType === "sales" ? propertiesSaleURL
  : dynamicPageData?.department === "residential" && dynamicPageData?.searchType === "lettings" ? propertiesRentURL : offplanResultsURL


  return (
    <div className="alldepartments-popular-search">
      <div className="popular_links_holder">
      <div className="default-psearch-wrapper psearch">
      <h4>Popular Searches</h4>

        {pType ? (
          <div>
            {isOffplan ?
            <div className="column-links">
           {bedCount !== "1" &&  <div>
                <Link to={`${propertyUrl}${area === "Dubai" ? `type-${pType?.toLocaleLowerCase()}/more-than-1-bedrooms/` : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/type-${pType?.toLocaleLowerCase()}/more-than-1-bedrooms/`}`}>
                1 bedroom {propertyText} {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "2" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? `type-${pType?.toLocaleLowerCase()}/more-than-2-bedrooms/` : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/type-${pType?.toLocaleLowerCase()}/more-than-2-bedrooms/`}`}>
                2 bedroom {propertyText} {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "3" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? `type-${pType?.toLocaleLowerCase()}/more-than-3-bedrooms/` : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/type-${pType?.toLocaleLowerCase()}/more-than-3-bedrooms/`}`}>
                  3 bedroom {propertyText} {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "4" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? `type-${pType?.toLocaleLowerCase()}/more-than-4-bedrooms/` : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/type-${pType?.toLocaleLowerCase()}/more-than-4-bedrooms/`}`}>
                  4 bedroom {propertyText} {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "5" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? `type-${pType?.toLocaleLowerCase()}/more-than-5-bedrooms/` : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/type-${pType?.toLocaleLowerCase()}/more-than-5-bedrooms/`}`}>
                5 bedroom {propertyText} {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "6" && 
              <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? `type-${pType?.toLocaleLowerCase()}/more-than-6-bedrooms/` : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/type-${pType?.toLocaleLowerCase()}/more-than-6-bedrooms/`}`}>
                6 bedroom {propertyText} {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
          </div>: 
           <div className="column-links">
             {bedCount !== "1" &&  <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify(pType),
          })}${area === "Dubai" ? "more-than-1-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-1-bedrooms/`}`}>
                1 bedroom {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "2" &&   <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify(pType),
          })}${area === "Dubai" ? "more-than-2-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-2-bedrooms/`}`}>
                2 bedroom {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "3" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify(pType),
          })}${area === "Dubai" ? "more-than-3-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-3-bedrooms/`}`}>
                  3 bedroom {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "4" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify(pType),
          })}${area === "Dubai" ? "more-than-4-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-4-bedrooms/`}`}>
                  4 bedroom {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "5" &&  <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify(pType),
          })}${area === "Dubai" ? "more-than-5-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-5-bedrooms/`}`}>
                5 bedroom {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "6" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify(pType),
          })}${area === "Dubai" ? "more-than-6-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-6-bedrooms/`}`}>
                6 bedroom {pType?.replace(/-/g, " ")?.toLocaleLowerCase()} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
            </div>}
            
          </div>
        ) : bedCount ? (
          <div>
            {isOffplan ?
            <div className="column-links">
           {bedCount !== "1" && <div>
                <Link to={`${propertyUrl}${area === "Dubai" ? "more-than-1-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-1-bedrooms/`}`}>
                1 bedroom {propertyText} properties {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "2" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "more-than-2-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-2-bedrooms/`}`}>
                2 bedroom {propertyText} properties {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "3" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "more-than-3-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-3-bedrooms/`}`}>
                  3 bedroom {propertyText} properties {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "4" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "more-than-4-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-4-bedrooms/`}`}>
                  4 bedroom {propertyText} properties {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "5" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "more-than-5-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-5-bedrooms/`}`}>
                5 bedroom {propertyText} properties {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "6" && <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "more-than-6-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-6-bedrooms/`}`}>
                6 bedroom {propertyText} properties {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
          </div>: 
           <div className="column-links">
              {bedCount !== "1" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent"
          })}${area === "Dubai" ? "more-than-1-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-1-bedrooms/`}`}>
                1 bedroom {propertyText} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "2" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent"
          })}${area === "Dubai" ? "more-than-2-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-2-bedrooms/`}`}>
                2 bedroom {propertyText} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "3" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent"
          })}${area === "Dubai" ? "more-than-3-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-3-bedrooms/`}`}>
                  3 bedroom {propertyText} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "4" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent"
          })}${area === "Dubai" ? "more-than-4-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-4-bedrooms/`}`}>
                  4 bedroom {propertyText} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "5" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent"
          })}${area === "Dubai" ? "more-than-5-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-5-bedrooms/`}`}>
                5 bedroom {propertyText} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              {bedCount !== "6" && <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent"
          })}${area === "Dubai" ? "more-than-6-bedrooms/" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/more-than-6-bedrooms/`}`}>
                6 bedroom {propertyText} {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
            </div>}
            
          </div>
        ) : (
          <div>
            {isOffplan ?
            <div className="column-links">
            <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}type-apartment/`}>
              {propertyText} apartments {status} in {capitalize(area?.replace(/-/g, " "))}
              </Link>
            </div>
            <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}type-studio/`}>
              {propertyText} studios {status} in {capitalize(area?.replace(/-/g, " "))}
              </Link>
            </div>
            
            <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}type-sky-villa/`}>
              {propertyText} sky villas {status} in {capitalize(area?.replace(/-/g, " "))}
              </Link>
            </div>
            <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}type-mansions/`}>
              {propertyText} mansions {status} in {capitalize(area?.replace(/-/g, " "))}
              </Link>
            </div>
            <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}type-villa/`}>
              {propertyText} villas {status} in {capitalize(area?.replace(/-/g, " "))}
              </Link>
            </div>
            <div>
              <Link to={`${propertyUrl}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}type-townhouse/`}>
              {propertyText} townhouses {status} in {capitalize(area?.replace(/-/g, " "))}
              </Link>
            </div>
          </div>: 
           <div className="column-links">
              <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify("apartment"),
          })}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}`}>
                Apartments {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>
              <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify("villa"),
          })}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}`}>
                Villas {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>
              <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify("townhouse"),
          })}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}`}>
                  Townhouses {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>
              <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify("penthouse"),
          })}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}`}>
                  Penthouses {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>
              {dynamicPageData?.searchType !== "sales" &&
              <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify("short-term"),
          })}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}`}>
                Short terms {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>}
              <div>
                <Link to={`${generatePropertyURL({
            searchType: dynamicPageData?.searchType === "sales" ? "for-sale" : dynamicPageData?.department==="Offplan"?"for-sale":"to-rent",
            buildingType: slugify("duplex"),
          })}${area === "Dubai" ? "" : `in-${area?.toLowerCase()?.replace(/ /g, "-")}/`}`}>
                Duplexes {status} in {capitalize(area?.replace(/-/g, " "))}
                </Link>
              </div>
            </div>}
            
          </div>
        )}
        </div>
      </div>
    </div>
  )
}

export default ResidentialPopularSearch
