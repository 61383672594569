import ReadMore from "@components/ReadMore/ReadMore"
import Animation from "@components/elements/Animation"
import { communitiesURL } from "@lib/urls"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import "./PropertyAreaguideSR.scss"
import GGFXImage from "@components/elements/GGFXImage"
import { parseHTML } from "@lib/utils"
import useAreaguidesFilter from "@hooks/dataHooks/useAreaguidesFilter"

const PropertyAreaguideSR = ({ areas }) => {
  const allAreas = useAreaguidesFilter()
  const [filteredArea, setFilteredArea] = useState([])
  const path = 
  (typeof window !== "undefined" && window.location.pathname)
    useEffect(() => {
      let filteredAreaTemp = allAreas?.filter(area => {
        // Check if the community field in team contains a matching slug or name
        return areas.some(item => {
          return area?.title?.includes(item.name)
        });
      });
      setFilteredArea(filteredAreaTemp?.length > 0 && filteredAreaTemp?.[0])

  }, [path, allAreas]);
  return ( filteredArea &&
    <Animation
      animateInType="up"
      className="property-areaguide-wrap sr"
      id="areaGuidesection"
    >
      {filteredArea && (
        <Link
        to={`${communitiesURL + filteredArea.slug}/`}
        // className="img-section img-zoom"
      >

          <div className="property-areaguide-section">
          {filteredArea.tile_image && (
              <Link
                to={`${communitiesURL + filteredArea.slug}/`}
                className="img-section img-zoom"
              >
                <GGFXImage
                  ImageSrc={filteredArea.tile_image}
                  altText={`${filteredArea.title} area guide`}
                  imagetransforms={filteredArea?.ggfx_results}
                  renderer="srcSet"
                  imagename="area-guide.tile_image.tile_img"
                  strapiID={filteredArea?.strapi_id}
                />
                {/* <img
                  src={area.tile_image.url}
                  alt={`${area.title} area guide`}
                /> */}
              </Link>
            )}
            {/* <p className="heading d-block d-md-none">{area.title} area guides</p> */}

           


            <div className="content-section">
              <div>
              <p className="heading">{filteredArea.title} area guide</p>
              {filteredArea.content?.data?.content && (
                <div class="read-more-wrap read-more"><div class="read-more-section read-more" style={{ maxHeight: "70px" }}>
                  {parseHTML(filteredArea.content?.data?.content?.replace(/<\/?[^>]+(>|$)/g, ""))}</div>
                  <Link to={`${communitiesURL + filteredArea.slug}/`} class="button button-white-outline">View Area Guide</Link>
                </div>)}

                </div>
            </div>

          </div>
          <div className="divider" />
        </Link>
      )}

    </Animation>
  )
}

export default PropertyAreaguideSR
