import TeamCard from "@components/TeamCard/TeamCard"
import useTeams from "@hooks/dataHooks/useTeams"
import React, { useEffect, useState, useRef } from "react"
import "./TeamsSlider.scss"
import SliderTeamModule from "@components/SliderModule/SliderTeamModule"
import { shuffle } from 'lodash';
const SRTeamsSlider = ({ heading, area }) => {
  const allTeams = useTeams()
  const [randomTeams, setRandomTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  // const hasShuffled = useRef(false);
  const path = 
  (typeof window !== "undefined" && window.location.pathname)

  if (!module) return null
  

  useEffect(() => {
    // if (!hasShuffled.current) {
      const teamsToDisplay = allTeams.filter(team =>
        team?.category?.strapi_json_value?.some(cat => cat === "Brokers")
      );

      const shuffledTeams = shuffle(teamsToDisplay).slice(0, 6);
      setRandomTeams(shuffledTeams);
      if(area?.length > 0){
      const filteredTeamss = teamsToDisplay.filter(team => {
        // Check if the community field in team contains a matching slug or name
        return area?.some(item => {
          return team?.community?.strapi_json_value?.some(value =>
            value.includes(item.name)
          );
        });
        
          // return team?.community?.strapi_json_value?.some(value =>
          //   value.includes(area)
          // );
       
      });
      const shuffledTeams1 = shuffle(filteredTeamss).slice(0, 6);
        setFilteredTeams(shuffledTeams1)
    }
    //   hasShuffled.current = true; // Prevent further shuffling
    // }
  }, [allTeams, path]);


  const sliderChildren =  filteredTeams?.length > 0 ? filteredTeams
    ?.slice(0, 6)
    .map((team) => <TeamCard key={team.id} team={team} isTeam/>) : randomTeams
    ?.slice(0, 6)
    .map((team) => <TeamCard key={team.id} team={team} isTeam/>)

  return (
    sliderChildren?.length > 0 &&
    <SliderTeamModule
      title={heading}
      // title={title}
      // ctaText={cta_text}
      sliderChildren={sliderChildren}
      wrapperClassname="teams-slider-wrap"
      size="xl"
      sectionClassname="section-m"
    />
  )
}

export default SRTeamsSlider
