import React, { useEffect, useState } from "react"
import "./PropertyAreaguideSR.scss"
import GGFXImage from "@components/elements/GGFXImage"
import CTALink from "@components/elements/CTALink"
import { graphql, useStaticQuery } from "gatsby"
import "./ProjectSpotlight.scss"
const ProjectSpotlight = ({ content_cta }) => {
const data = useStaticQuery(graphql`
    query {
      strapiGlobalMenu {
        header_links {
          id
          content_cta {
            ...contentCtaFragment
          }
          sub_menu_title
          sub_menu_bot_title
          isoffplan
          ismegamenu
          top_title
          isspotlight
        }
        ggfx_results {
          ...STRAPI_GGFX_RESULTFragment
        }
        strapi_id
      }
    }
  `)

  return (data?.strapiGlobalMenu?.header_links?.length > 0 && data?.strapiGlobalMenu?.header_links?.map((item, i) =>{
    const trackSpot = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "Spotlight",
        formType: `Spotlight`,
      })
    }
    return(item.isspotlight &&
<div className="content-cta-section sub-menu offplan">
                            <div className="image-bg">
                              <GGFXImage
                              ImageSrc={item.content_cta?.image}
                              altText="banner-bg"
                              imagetransforms={content_cta?.ggfx_results}
                              renderer="srcSet"
                              imagename={"global-menu.content_cta.image"}
                              strapiID={content_cta?.strapi_id}
                            /><div className="spotlight">Spotlight Property</div>
                              {/* <img src={content_cta?.image?.url} /> */}
                              <div className="content">
                                <p className="heading">{item.content_cta.title}</p>
                                {item.content_cta.description &&<p className="description">
                                  {item.content_cta.description}
                                </p>}
                                <CTALink
                                  cta={item.content_cta.cta}
                                  className="button button-orange"
                                  onClick={()=> {
                                    trackSpot()
                                  }}
                                  istrackSpot
                                />
                              </div>
                            </div>

                          </div>)
  })
    
  )
}

export default ProjectSpotlight
