//import Layout from "@components/Layout/Layout"+
import Header from "@components/Header/Header"
import Footer from "@components/Footer/Footer"
// import PropertyMortgageCalc from "@components/PropertyMortgageCalc/PropertyMortgageCalc"
// import InfoMapSort from "@components/SearchResults/InfoMapSort/InfoMapSort"
import PropertyList from "@components/SearchResults/PropertyList/PropertyList"
// import SearchFilters from "@components/SearchResults/SearchFilters/SearchFilters"
// import SearchPagination from "@components/SearchResults/SearchPagination/SearchPagination"
// import useInitPropertyData from "@components/SearchResults/hooks/useInitPropertyData"
// import useUrlData from "@components/SearchResults/hooks/useUrlData"
// import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
// import PropertyCopy from "@components/TextCopy/PropertyCopy"
// import { getAreaPath, getH1Text, getSeoDesc } from "@lib/searchUtils"
// import {
//   propertiesRentURL,
//   propertiesSaleURL,
//   propertyRentRegex,
//   propertySaleRegex,
// } from "@lib/urls"
// import loadable from "@loadable/component"
import "@styles/search-results.scss"
import clsx from "clsx"
import React, { useEffect, useMemo, useRef, useState } from "react"
// import { Container } from "react-bootstrap"
// import ResultsBreadcrumb from "@components/PropertyBreadcrumb/ResultsBreadcrumb"
// import { navigate } from "gatsby"
// import QuestionnaireBannerCom from "@components/QuestionnaireBannerCom/QuestionnaireBannerCom"
// import useHasScrolled from "@hooks/useHasScrolled"
// import {
//   UserObjectStoreProvider,
//   getUserobjectData,
//   isAuthenticated,
// } from "@starberry/myaccount-website-utils"
// import SRTeamsSlider from "@components/TeamsSlider/SRTeamsSlider"
// import useTeams from "@hooks/dataHooks/useTeams"
// import PropertyNegoCardSR from "@components/PropertyNegoCard/PropertyNegoCardSR"
// import PropertyAreaguideSR from "@components/PropertyAreaguide/PropertyAreaguideSR"
// import useAreaguides from "@hooks/dataHooks/useAreaguides"
// import ResidentialPopularSearch from "@components/PopularSearchModule/PopularSearchModule"
// import { useGlobalStore } from "@stores/globalStore"
// import ProjectSpotlight from "@components/PropertyAreaguide/ProjectSpotlight"
// import UsefulLInks from "@components/PopularSearchModule/UsefulLInks"
// import Cardsidebar from "@components/PopularSearchModule/Cardsidebar"
// import SidebarProjects from "@components/SidebarProjects/SidebarProjects"
// import { resiPropertyTypeOptions } from "@components/SearchResults/SearchFilters/filterOptions"
// import { shuffle } from "lodash"
// const PropertyMap = loadable(() =>
//   import("../components/SearchResults/map/RenderMap")
// )

const SearchResultsPage = React.memo(({ location }) => {
  const scrolled = true//useHasScrolled()
  const [isLoad, setLoad] = useState(false)
  // useEffect(() => {
  //   setLoad(true)
  // })
  const path =
    location.pathname ||
    (typeof window !== "undefined" && window.location.pathname)

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("searchpath", path)
    }
  }, [path])

  //useInitPropertyData()
  // const pageUrlData = useUrlData()
  // let h1Text
  // if (pageUrlData) {
  //   h1Text = getH1Text(pageUrlData)
  // }
  // const seoDesc = getSeoDesc(pageUrlData)
  // const showMap = usePropertyStore((state) => state.showMap)
  // const pType = usePropertyStore((state) => state.pType)
  // const pDepartment = usePropertyStore((state) => state.pDepartment)
  const selectedAreas = []//usePropertyStore((state) => state.selectedAreas)
  // const buildingType = usePropertyStore((state) => state.buildingType)
  // const searchResult = usePropertyStore((state) => state.searchResult)
  // const { minBedroom, maxBedroom } = usePropertyStore()
  // const listView = usePropertyStore((state) => state.listView)
  //const { nbHits, nbPages, page } = searchResult
  const { nbHits, nbPages, page } = { nbHits: "9", nbPages: "20", page: "0" }

  // const dynamicData = useMemo(
  //   () => ({
  //     department: pDepartment,
  //     area:
  //       selectedAreas?.length > 0
  //         ? getAreaPath(selectedAreas)?.replace("in-", "")?.replace("/", "")
  //         : "dubai",
  //     searchType: pType,
  //     bedroom: minBedroom?.value,
  //     propertyType: buildingType?.value,
  //   }),
  //   [pDepartment, selectedAreas, pType, minBedroom, buildingType]
  // )

  useEffect(() => {
    if (nbHits > 0) {
      // enable other components after 1 second
      setLoad(true)
    }
  }, [nbHits])

  // check if the url is valid, else redirect to 404
  // if (!(path.match(propertyRentRegex) || path.match(propertySaleRegex))) {
  //   typeof window !== "undefined" &&
  //     path?.includes("/buy/") &&
  //     navigate("/buy/properties-for-sale/", { replace: true })
  //   typeof window !== "undefined" &&
  //     path?.includes("/let/") &&
  //     navigate("/let/properties-for-rent/", { replace: true })
  // }

  const [userObjects, setUserObjects] = useState({})
  // useEffect(() => {
  //   let isMounted = true
  //   if (isAuthenticated()) {
  //     const getUserObjects = async () => {
  //       try {
  //         const userObjects = await getUserobjectData()
  //         if (isMounted) setUserObjects(userObjects.data.data)
  //       } catch (e) {}
  //     }
  //     getUserObjects()
  //   }
  //   // Redirect to 404 if the URL is not valid
  //   let arr = resiPropertyTypeOptions.slice(1).map((item, i) => {
  //     return item.value + "-"
  //   })
  //   const matchURLs = [
  //     "above-",
  //     "below-",
  //     "between-",
  //     "from-",
  //     "to-",
  //     "under-",
  //     "more-than-",
  //     "-bedrooms",
  //     "-sqft",
  //     "in-",
  //     "page-",
  //     "sortby-",
  //     "with-amenities-",
  //     "crm-id-",
  //     "furnished",
  //   ]
  //   const matchURLs1 = [...arr]
  //   const path2 = path.split("/")[2] || ""
  //   const path3 = path.split("/")[3] || ""
  //   if (
  //     !matchURLs1?.some((matchURL) => path2.includes(matchURL)) &&
  //     !matchURLs?.some((matchURL) => path3.includes(matchURL)) &&
  //     (path !== propertiesSaleURL || path !== propertiesRentURL)
  //   ) {
  //     typeof window !== "undefined" &&
  //       path?.includes("/buy/") &&
  //       navigate("/buy/properties-for-sale/", { replace: true })
  //     typeof window !== "undefined" &&
  //       path?.includes("/let/") &&
  //       navigate("/let/properties-for-rent/", { replace: true })
  //   }
  //   return () => {
  //     isMounted = false // Cleanup
  //   }
  // }, [isLoad])
  // const allTeams = useTeams()
  // const allAreas = useAreaguides()
  // // Function to filter allTeams by community
  // const filterByCommunity = (teams, communities) => {
  //   return teams.filter((team) => {
  //     // Check if the community field in team contains a matching slug or name
  //     return communities.some((community) => {
  //       return team?.community?.strapi_json_value?.some((value) =>
  //         value.includes(community.name)
  //       )
  //     })
  //   })
  // }
  // const filterByAreaguide = (areas, communities) => {
  //   return areas.filter((area) => {
  //     // Check if the community field in team contains a matching slug or name
  //     return communities.some((community) => {
  //       return area?.title.includes(community.name)
  //     })
  //   })
  // }
  const [filteredData, setFilteredData] = useState([])
  // const [filteredArea, setFilteredArea] = useState([])

  // useEffect(() => {
  //   let filteredDataTemp = []
  //   let filteredAreaTemp = []
  //   if (selectedAreas && selectedAreas.length > 0) {
  //     filteredDataTemp = filterByCommunity(allTeams, selectedAreas)
  //     filteredAreaTemp = filterByAreaguide(allAreas, selectedAreas)
  //   }

  //   // If no teams were found, check for "Laura Victoria Adams"
  //   if (filteredDataTemp.length === 0) {
  //     filteredDataTemp = allTeams.filter(
  //       (team) => team?.name === "Laura Victoria Adams"
  //     )
  //   }
  //   const shuffledTeams = shuffle(filteredDataTemp)
  //   setFilteredData(shuffledTeams)
  //   setFilteredArea(filteredAreaTemp)
  // }, [selectedAreas])
  // const { spotlight, sidebarProject } = useGlobalStore()

  // const renderCount = useRef(0);
  // useEffect(() => {
  //     renderCount.current += 1;
  //     console.log(`Render count: ${renderCount.current}`);
  // });
  return (
    <>
    <Header  />
    <div
      pageData={{
        page_class: clsx(
          "search-results-page",
          nbHits === 0 && "no-properties-page",
          scrolled && "search-fit"
        ),
        layout: "search-results",
      }}
      hideNewsletter
    >
      {/* {showMap ? (
        <div className={clsx("search-map-wrapper")}>
          <PropertyMap />
        </div>
      ) : ( */}
        <>
          <div className="se-r min-vh-100">
            {isLoad && (
              <>
                {/* <SearchFilters istrue userObjects={userObjects} /> */}

                {/* <ResultsBreadcrumb
                  path={path}
                  search_type={pType}
                  isLoad={isLoad}
                  h1Text={h1Text ? h1Text : ""}
                  department={pDepartment}
                /> */}
                <div className={clsx("search-results-section list-page")}>
                  {/* <InfoMapSort
                    h1Text={h1Text}
                    isLoad={isLoad}
                    istrue
                    pType={pType}
                  /> */}
                  <div
                    className={clsx(
                      "new-layout-with-sidebar container",
                      "list-k",
                      nbHits === "0" ||
                        (nbPages === 0 && page === 0 && "no-result")
                    )}
                  >
                    <div>
                      <PropertyList
                        selectedAreas={selectedAreas}
                        userObjects={userObjects}
                        filteredData={filteredData}
                      />
                      {/* {nbPages > page && <SearchPagination />} */}
                    </div>
                    {/* {nbHits !== 0 && (
                      <div className="side-bar-listing-page">
                        <div className="sticky-container">
                          <ProjectSpotlight content_cta={spotlight} />
                          <ResidentialPopularSearch
                            dynamicPageData={dynamicData}
                          />
                          <UsefulLInks />
                          <PropertyNegoCardSR team_data={filteredData} />
                          {Object.entries(sidebarProject).length !== 0 && (
                            <SidebarProjects
                              minsBeds={sidebarProject.min_bedrooms}
                              maxBeds={sidebarProject.max_bedrooms}
                              displayBedrooms={sidebarProject.display_bedrooms}
                              buldingType={sidebarProject.building_type}
                              image={
                                sidebarProject.ads_image ||
                                sidebarProject.banner_image ||
                                sidebarProject.media_images?.[0] ||
                                sidebarProject.images?.[0] ||
                                ""
                              }
                              title={sidebarProject.title}
                              developer={sidebarProject.developer}
                              location={sidebarProject.display_address}
                              slug={sidebarProject.slug}
                              videoURL={sidebarProject.video_module?.video_url}
                              community={sidebarProject.community?.title}
                              key={sidebarProject.id}
                              completionYear={sidebarProject.completion_year}
                              price={sidebarProject.price}
                              listView={true}
                              description={sidebarProject.about?.data}
                              future_launch={sidebarProject.future_launch}
                              ggfx_results={sidebarProject.ggfx_results}
                              strapi_id={sidebarProject.strapi_id}
                              property={sidebarProject}
                              ads_sidebar_image={
                                sidebarProject?.ads_sidebar_image
                                  ? sidebarProject?.ads_sidebar_image
                                  : ""
                              }
                            />
                          )}
                          <PropertyAreaguideSR area={filteredArea?.[0]} />
                          <Cardsidebar
                            pType={
                              pType === "sales"
                                ? "sale"
                                : pType === "lettings"
                                ? "rent"
                                : "projects"
                            }
                          />
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
                {/* {nbHits > 0 && (
                  <SRTeamsSlider
                    heading={`Contact Property Expert in ${
                      selectedAreas?.length > 0
                        ? selectedAreas?.map((area) => area.name).join(",")
                        : "Dubai"
                    }`}
                    area={
                      selectedAreas?.length > 0
                        ? selectedAreas
                        : [{ name: "dubai" }]
                    }
                  />
                )} */}
              </>
            )}
          </div>
          {/* {isLoad && (
            <>
              <div className="results-calculator section-m">
                <Container>
                  <PropertyMortgageCalc
                    pageComp
                    heading="Mortgage Calculator"
                    title="Calculate your monthly mortgage repayments"
                  />
                </Container>
              </div>
              <Container>
                <QuestionnaireBannerCom />
              </Container>
              <PropertyCopy
                pType={pType}
                buildingType={buildingType}
                pDepartment={pDepartment}
                selectedAreas={selectedAreas}
                isProperty
              />
            </>
          )} */}
        </>
      {/* )} */}
    </div>
    <Footer />
    </>

  )
})

const SearchResultComp = (props) => {
  return (
    // <UserObjectStoreProvider>
    <SearchResultsPage {...props} />
    // </UserObjectStoreProvider>
  )
}
export default SearchResultComp
