import React, { useEffect, useState } from "react"
import "./Layout.scss"
import Header from "@components/Header/Header"
import Footer from "@components/Footer/Footer"
import clsx from "clsx"
import $ from "jquery"
import useInteractive from "@hooks/useInteractive"
import { UserObjectStoreProvider } from "@starberry/myaccount-website-utils"
import { isbot } from "isbot";
const Layout = ({ children, pageData, transparentHeader, hideNewsletter }) => {
  const { page_class, layout } = pageData || {}
  const { hasInteracted } = useInteractive()
  useEffect(() => {
    const isBotTrue = typeof window !== "undefined" && isbot(navigator.userAgent)
    if(!isBotTrue){
      // Check if it's the first time the app is loaded
   if (!localStorage.getItem("providenthasVisited")) {
    // Get the ipData if it exists
    const ipData = localStorage.getItem("ipData");

    // Clear all local storage
    localStorage.clear();

    // Restore ipData after clearing
    if (ipData) {
      localStorage.setItem("ipData", ipData);
    }

    // Set a flag to indicate that the app has been visited
    localStorage.setItem("providenthasVisited", "true");
  }
    let chatbotRemoved = false;
  
    // Function to remove the chatbot
    const removeChatbot = () => {
      if (!chatbotRemoved) {
        const elem = document.getElementById("chatbot");
        if (elem) {
          elem.remove();
          chatbotRemoved = true;
        }
      }
    };
  
    // Remove existing Landbot elements when component mounts
    removeChatbot();
    const landbotPopupElems = document.getElementsByClassName("LandbotPopup");
    if (landbotPopupElems.length > 0) {
      $("body .LandbotPopup").remove();
    }
  
    // Check if the script is already present
    if (!document.getElementById("chatbot")) {
      // Load Landbot script
      const script = document.createElement("script");
      script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js";
      script.async = true;
      script.id = "chatbot";
      script.onload = () => {
        // Define the Landbot popup configuration
        const myLandbotpop = new window.Landbot.Popup({
          configUrl: "https://storage.googleapis.com/chats.landbot.io/v3/H-1167807-EII5RTVGT6GG4053/index.json",
        });
        // Add click event listeners to elements with id="openbot"
        document.querySelectorAll("#openbot").forEach((node) => {
          node.addEventListener("click", () => {
            // Open Landbot popup when clicked
            myLandbotpop.open();
          });
        });
        if (localStorage.getItem("landbotPopupShown") === "true") {
          myLandbotpop.onLoad(function() {
            setTimeout(() => {
            myLandbotpop.hideProactive()
           
            }, 10);
          })
        }
        else {
          localStorage.setItem("landbotPopupShown", "true");
        }
      };
  
      // Append the script to the document body
      setTimeout(() => {
        document.body.appendChild(script);
      }, 100);
    }
 
    // Cleanup function to remove chatbot
    return () => {
      removeChatbot();
    };
  }
  }, []);
  
  return (
    <UserObjectStoreProvider>
    <div className={clsx(page_class, "page-layout", `${layout}-layout`)}>
      <Header transparentHeader={transparentHeader} />
      {children}
      <Footer hideNewsletter={hideNewsletter} />
    </div>
    </UserObjectStoreProvider>
  )
}

export default Layout
