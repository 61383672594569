import { graphql, useStaticQuery } from "gatsby"

const useAreaguidesFilter = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiAreaGuide(
        filter: { publish: { eq: true } }
        sort: { fields: rank, order: ASC }
      ) {
        nodes {
          id
          title
          tile_image {
            url
          }
            content {
            data {
              content
            }
          }
          strapi_id
          slug
          ggfx_results {
            ...STRAPI_GGFX_RESULTFragment
          }
        }
      }
    }
  `)

  return data?.allStrapiAreaGuide?.nodes
}

export default useAreaguidesFilter
