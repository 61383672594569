import React, { useEffect, useMemo, useState } from "react"
import fetch from "node-fetch"
//import useUrlData from "@components/SearchResults/hooks/useUrlData"
import { addTraillingSlash, generateFilters, parseResultsUrl } from "@lib/searchUtils"
import {
  createResultsCanonicalUrl,
  getH1Text,
  getSeoDesc,
} from "@lib/searchUtils"
import RSeo from "@components/resultsseo"
import "@styles/search-results.scss"
import logo from "@images/logo.svg"
import { graphql, useStaticQuery } from "gatsby"
//const { useLocation } = require("@reach/router")
import PropertyCard from "@components/PropertyCard/PropertyCard"
import clsx from "clsx"
import "@styles/search-results.scss"
import Layout from "@components/Layout/Layout"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import SearchFilters from "@components/SearchResults/SearchFilters/SearchFilters"
import ResultsBreadcrumb from "@components/PropertyBreadcrumb/ResultsBreadcrumb"
import InfoMapSort from "@components/SearchResults/InfoMapSort/InfoMapSort"
import useHasScrolled from "@hooks/useHasScrolled"
// import { isAuthenticated } from "@starberry/myaccount-website-utils"
import { resiPropertyTypeOptions } from "@components/SearchResults/SearchFilters/filterOptions"
import { propertiesRentURL, propertiesSaleURL } from "@lib/urls"
import { navigate } from "gatsby"
import PropertyMortgageCalc from "@components/PropertyMortgageCalc/PropertyMortgageCalc"
import QuestionnaireBannerCom from "@components/QuestionnaireBannerCom/QuestionnaireBannerCom"
import PropertyCopy from "@components/TextCopy/PropertyCopy"
import { Container } from "react-bootstrap"
import SRTeamsSlider from "@components/TeamsSlider/SRTeamsSlider"
import SearchPagination from "@components/SearchResults/SearchPagination/SearchPagination"
import ProjectSpotlight from "@components/PropertyAreaguide/ProjectSpotlight"
import ResidentialPopularSearch from "@components/PopularSearchModule/PopularSearchModule"
import UsefulLInks from "@components/PopularSearchModule/UsefulLInks"
import PropertyNegoCardSR from "@components/PropertyNegoCard/PropertyNegoCardSR"
import SidebarProjects from "@components/SidebarProjects/SidebarProjects"
import PropertyAreaguideSR from "@components/PropertyAreaguide/PropertyAreaguideSR"
import Cardsidebar from "@components/PopularSearchModule/Cardsidebar"
import useInitPropertyData from "@components/SearchResults/hooks/useInitPropertyData"
import { getAreaPath } from "@lib/searchUtils"
import useTeams from "@hooks/dataHooks/useTeams"
// import useAreaguides from "@hooks/dataHooks/useAreaguides"
// import { useGlobalStore } from "@stores/globalStore"
import SponsoredProjects from "@components/SponsoredProjects/SponsoredProjects"
import { useGlobalStore } from "@stores/globalStore"
import allProjects from "../../../static/all_projects.json"
import AdsBanner from "@components/AdsBanner/AdsBanner"
import AdsBannerOff from "@components/AdsBanner/AdsBannerOff"
import {
  UserObjectStoreProvider,
  getUserobjectData,
  isAuthenticated,
} from "@starberry/myaccount-website-utils"
import { shuffle } from "lodash"
import { slugToTitle } from "@lib/utils"
import loadable from "@loadable/component"
import NoResults from "@components/SearchResults/NoResults/NoResults"
const PropertyMap = loadable(() =>
    import("../../components/SearchResults/map/RenderMap")
  )
const SearchResults = ({ serverData, location }) => {
  // console.log(serverData)
  const listView = usePropertyStore((state) => state.listView)
  const offlistView = usePropertyStore((state) => state.offlistView)
  const properties = serverData?.hits
  const showMap = usePropertyStore((state) => state.showMap)
  const pType = usePropertyStore((state) => state.pType)
  const pDepartment = usePropertyStore((state) => state.pDepartment)
  const selectedAreas = usePropertyStore((state) => state.selectedAreas)
  const isOffplan = pDepartment === "new_developments"
  let propertyBanner = isOffplan
    ? "offplanBanners"
    : pType === "sales"
    ? "propertySaleBanners"
    : "propertyRentBanners"

  useInitPropertyData()
  const path = typeof window !== "undefined" && window.location.pathname

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("searchpath", path)
    }
  }, [path])
  const pageUrlData = parseResultsUrl(location)
  let h1Text
  if (pageUrlData) {
    h1Text = getH1Text(pageUrlData)
  }
  const { minBedroom, maxBedroom } = usePropertyStore()
  // const listView = usePropertyStore((state) => state.listView)
  // const { nbHits, nbPages, page } = searchResult
  const { nbHits, nbPages, page } = {
    nbHits: serverData?.nbHits,
    nbPages: serverData?.nbPages,
    page: serverData?.page,
  }

  const scrolled = useHasScrolled()
  const [isLoad, setLoad] = useState(true)

  const buildingType = usePropertyStore((state) => state.buildingType)
  const dynamicData = useMemo(
    () => ({
      department: pDepartment,
      area:
        selectedAreas?.length > 0
          ? getAreaPath(selectedAreas)?.replace("in-", "")?.replace("/", "")
          : "dubai",
      searchType: pType,
      bedroom: minBedroom?.value,
      propertyType: buildingType?.value,
    }),
    [pDepartment, selectedAreas, pType, minBedroom, buildingType]
  )
  const [userObjects, setUserObjects] = useState({})
  useEffect(() => {
    let isMounted = true
    if (isAuthenticated()) {
      const getUserObjects = async () => {
        try {
          const userObjects = await getUserobjectData()
          if (isMounted) setUserObjects(userObjects.data.data)
        } catch (e) {}
      }
      getUserObjects()
    }
    // Redirect to 404 if the URL is not valid
    let arr = resiPropertyTypeOptions.slice(1).map((item, i) => {
      return item.value + "-"
    })
    const matchURLs = [
      "above-",
      "below-",
      "between-",
      "from-",
      "to-",
      "under-",
      "more-than-",
      "-bedrooms",
      "-sqft",
      "in-",
      "page-",
      "sortby-",
      "with-amenities-",
      "crm-id-",
      "furnished",
    ]
    const matchURLs1 = [...arr]
    const path2 = path.split("/")[2] || ""
    const path3 = path.split("/")[3] || ""
    if (
      !matchURLs1?.some((matchURL) => path2.includes(matchURL)) &&
      !matchURLs?.some((matchURL) => path3.includes(matchURL)) &&
      (path !== propertiesSaleURL || path !== propertiesRentURL)
    ) {
      typeof window !== "undefined" &&
        path?.includes("/buy/") &&
        navigate("/buy/properties-for-sale/", { replace: true })
      typeof window !== "undefined" &&
        path?.includes("/let/") &&
        navigate("/let/properties-for-rent/", { replace: true })
    }
    return () => {
      isMounted = false // Cleanup
    }
  }, [isLoad])
  const allTeams = useTeams()
  //   const allAreas = useAreaguides()
  // Function to filter allTeams by community
  const filterByCommunity = (teams, communities) => {
    return teams.filter((team) => {
      // Check if the community field in team contains a matching slug or name
      return communities.some((community) => {
        return team?.community?.strapi_json_value?.some((value) =>
          value.includes(community.name)
        )
      })
    })
  }
  //   const filterByAreaguide = (areas, communities) => {
  //     return areas.filter(area => {
  //       // Check if the community field in team contains a matching slug or name
  //       return communities.some(community => {
  //         return area?.title.includes(community.name)
  //       });
  //     });
  //   };
  const [filteredData, setFilteredData] = useState([])
  //   const [filteredArea, setFilteredArea] = useState([])

  useEffect(() => {
    let filteredDataTemp = []
    // let filteredAreaTemp = []
    if (selectedAreas && selectedAreas.length > 0) {
      filteredDataTemp = filterByCommunity(allTeams, selectedAreas)
      //   filteredAreaTemp = filterByAreaguide(allAreas, selectedAreas)
    }

    // If no teams were found, check for "Laura Victoria Adams"
    if (filteredDataTemp.length === 0) {
      filteredDataTemp = allTeams.filter(
        (team) => team?.name === "Laura Victoria Adams"
      )
    }
    const shuffledTeams = shuffle(filteredDataTemp)
    setFilteredData(shuffledTeams)
    // setFilteredArea(filteredAreaTemp)
  }, [selectedAreas])
  const { sidebarProject, setSidebarProject } = useGlobalStore()
  const [offplanProperties, setOffplanProperties] = useState([])
  useEffect(() => {
    if (!isOffplan) {
      const areaNames =
        selectedAreas?.map((area) => slugToTitle(area.name)) || []

      const filteredProperties = areaNames.length
        ? allProjects.nodes
            .filter((node) =>
              areaNames.some((name) =>
                node.display_address.toLowerCase().includes(name.toLowerCase())
              )
            )
            ?.sort((a, b) => {
              // Handle rank prioritization: null or undefined ranks go last
              if (a.rank == null && b.rank != null) return 1
              if (b.rank == null && a.rank != null) return -1
              if (a.rank != null && b.rank != null) return a.rank - b.rank

              // If ranks are equal or null, sort by updatedAt descending
              return new Date(b.updatedAt) - new Date(a.updatedAt)
            })
            ?.slice(0, 20)
        : allProjects.nodes
            ?.sort((a, b) => {
              // Handle rank prioritization globally
              if (a.rank == null && b.rank != null) return 1
              if (b.rank == null && a.rank != null) return -1
              if (a.rank != null && b.rank != null) return a.rank - b.rank

              // Sort remaining by updatedAt in descending order
              return new Date(b.updatedAt) - new Date(a.updatedAt)
            })
            ?.slice(0, 20)

      setOffplanProperties(filteredProperties)
      setSidebarProject(
        filteredProperties?.length > 0 ? filteredProperties[0] : []
      )
    }
  }, [pType, !isOffplan, selectedAreas])

  let { pathname } = location
  const seoDesc = getSeoDesc(pageUrlData)
  const [customCanonical, setCustomCanonical] = useState(
    process.env.GATSBY_SITE_URL + pathname
  )
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const listingpage = typeof window !== "undefined" && window.location.href
  var ldJson = {
    "@context": "https://schema.org/",
    "@id": "SearchResultsPage",
    "@type": ["SearchResultsPage", "Product"],
    accessModeSufficient: {
      "@type": "ItemList",
      name: h1Text,
      itemListOrder: "https://schema.org/ItemListOrderDescending",
      numberOfItems: serverData?.nbHits,
      itemListElement: [],
    },
    brand: {
      "@type": "Organization",
      name: "Provident Estate",
    },
    breadcrumb: {
      "@id": pageurl + "#breadcrumb",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          name: "Provident Estate",
          item: process.env.GATSBY_SITE_URL,
          position: 1,
        },
        {
          "@type": "ListItem",
          name: h1Text,
          item: listingpage,
          position: 2,
        },
      ],
    },
    contentLocation: {
      "@type": "Place",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Dubai",
      },
    },
    description: seoDesc,
    image:
      "https://d3h330vgpwpjr8.cloudfront.net/x.dev/1773x/888_4af09da9b9.webp",
    name: h1Text,
    offers: {
      "@type": "AggregateOffer",
      highPrice: "999999999",
      lowPrice: "100000",
      offerCount: serverData?.nbHits, 
      priceCurrency: "AED",
    },
    sameAs: "https://en.wikipedia.org/wiki/Search_engine_results_page",
    speakable: {
      "@type": "SpeakableSpecification",
    },
  }

  var ldjson1 = {
    "@context": "http://schema.org",
    "@id": "Organization",
    "@type": ["Organization", "Corporation"],
    logo: logo,
    url: process.env.GATSBY_SITE_URL,
    brand: {
      "@type": "Brand",
      name: "Provident Estate",
    },
    name: "Provident Estate",
    address: "#1802, 18th Floor, Marina Plaza, Dubai Marina, Dubai, UAE",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+971505390249",
      contactType: "customer service",
      areaServed: "ae",
      availableLanguage: ["en", "ar"],
    },
    sameAs: [
      "https://www.facebook.com/providentestate",
      "https://www.instagram.com/providentestate",
      "https://www.linkedin.com/company/providentestate",
      "https://www.youtube.com/@Providentestate",
      "https://twitter.com/providentagents",
    ],
  }

  useEffect(() => {
    const fetchCanonicalUrl = async () => {
      const canonicalUrl = await createResultsCanonicalUrl({ pageUrlData })
      setCustomCanonical(process.env.GATSBY_SITE_URL + canonicalUrl)
    }

    fetchCanonicalUrl()
  }, [pathname, h1Text])

  if (!customCanonical && !pageUrlData) {
    // You can return a loading state or default canonical URL here if needed
    return null
  }
  return (
    <UserObjectStoreProvider>
      <Layout
        pageData={{
          page_class: clsx(
            "search-results-page",
            // nbHits === 0 && "no-properties-page",
            scrolled && "search-fit"
          ),
          layout: "search-results",
        }}
        hideNewsletter
      >
        {!isLoad && <div className="loader-result"></div>}

        {
          showMap ? (
            <div className={clsx("search-map-wrapper")}>
              <PropertyMap searchResult={serverData} />
            </div>
          ) :
          <>
            <div className="se-r min-vh-100">
              {isLoad && (
                <RSeo
                  customCanonical={
                    customCanonical || process.env.GATSBY_SITE_URL + pathname
                  }
                  title={h1Text}
                  description={seoDesc}
                  isproperty
                  ldJson={ldJson}
                  ldjson1={ldjson1}
                />
              )}
              {isLoad && (
                <>
                  <SearchFilters istrue userObjects={userObjects} />

                  <ResultsBreadcrumb
                    path={path}
                    search_type={pType}
                    isLoad={isLoad}
                    h1Text={h1Text ? h1Text : ""}
                    department={pDepartment}
                  />
                  <div className={clsx("search-results-section list-page")}>
                    <InfoMapSort
                        h1Text={h1Text}
                      isLoad={isLoad}
                      istrue
                      pType={"sales"}
                      serverData={serverData}
                    />
                    <div
                      className={clsx(
                        "new-layout-with-sidebar container",
                        "list-k",
                        // nbHits === 0 ||
                          (nbHits === 0 && "no-result")
                      )}
                    >
                      <div>
                      {nbHits === 0 &&
    <NoResults selectedAreas={selectedAreas} filteredData={filteredData} searchResult={serverData} />
  } 
                        {/* <PropertyList selectedAreas={selectedAreas} userObjects={userObjects} filteredData={filteredData} /> */}
                        {serverData?.nbHits > 0 &&
                        <div
                          className={`property-list-container ${
                            isOffplan ? "container" : ""
                          }`}
                        >
                          <div
                            className={clsx(
                              "property-list-section",
                              !isOffplan && listView && "list-view",
                              isOffplan && offlistView && "list-view",
                              isOffplan && "isoffplan"
                            )}
                            id={`property-page-${serverData?.page}`}
                            key={`property-page-${serverData?.page}`}
                          >
                            {properties?.map((property, index) => {
                              let indexval
                              if (listView && (index === 2 || index === 5)) {
                                // Calculate the base index for the page
                                let baseIndex = serverData?.page * 2

                                // Determine the offset based on the index (2 comes first, then 7)
                                let offset = index === 2 ? 0 : 1

                                // Calculate indexval with reset after reaching 20
                                indexval = (baseIndex + offset) % 20
                              } else if (!listView && index === 3) {
                                // Calculate the base index for the page
                                let baseIndex = serverData?.page

                                // Determine the offset based on the index (2 comes first, then 7)
                                let offset = index === 3 ? 0 : null

                                // Calculate indexval with reset after reaching 20
                                indexval = baseIndex
                              }
                              const offplanProperty =
                                offplanProperties[indexval]
                              // const showOffplanCard = index === 1 || (index > 1 && (index - 2) % 4 === 0);
                              const showOffplanCard = listView
                                ? index === 2 || index === 5
                                : index === 3
                              return (
                                <React.Fragment
                                  key={`${property.objectID}-${index}`}
                                >
                                  <PropertyCard
                                    address={property.display_address}
                                    description={property.description}
                                    bathrooms={property.bathroom}
                                    bedrooms={property.bedroom}
                                    building={property.building?.[0]}
                                    image={property.images?.[0]}
                                    images={property.images}
                                    price={property.price}
                                    size={property.floorarea_min}
                                    searchType={property.search_type}
                                    slug={property.slug}
                                    crmID={property.crm_id}
                                    bitrix_id={property?.bitrix_id}
                                    strapiID={property.objectID}
                                    showProcessedImage
                                    noAnimation
                                    listView={listView}
                                    email={property?.link_to_employee?.email}
                                    crm_negotiator_id={
                                      property?.link_to_employee?.id
                                    }
                                    longDescription={property.long_description}
                                    department={property.department}
                                    phone={property?.link_to_employee?.phone}
                                    name={property?.link_to_employee?.name}
                                    singnatureProp={
                                      property?.search_type === "sales" &&
                                      property?.price >= 20000000 &&
                                      property?.department === "residential"
                                    }
                                    userObjects={userObjects}
                                    link_to_employee={
                                      property?.link_to_employee
                                    }
                                    isListing
                                    imageCount={property?.imageCount}
                                    property={property}
                                  />
                                  {showOffplanCard && offplanProperty && (
                                    <>
                                      <SponsoredProjects
                                        minsBeds={offplanProperty.min_bedrooms}
                                        maxBeds={offplanProperty.max_bedrooms}
                                        displayBedrooms={
                                          offplanProperty.display_bedrooms
                                        }
                                        buldingType={
                                          offplanProperty.building_type
                                        }
                                        image={
                                          offplanProperty?.ads_image
                                            ? offplanProperty?.ads_image
                                            : offplanProperty.banner_image
                                            ? offplanProperty.banner_image
                                            : offplanProperty?.media_images
                                                ?.length > 0
                                            ? offplanProperty?.media_images[0]
                                            : offplanProperty?.images?.length >
                                              0
                                            ? offplanProperty?.images[0]
                                            : ""
                                        }
                                        title={offplanProperty.title}
                                        developer={offplanProperty.developer}
                                        location={
                                          offplanProperty.display_address
                                        }
                                        slug={offplanProperty.slug}
                                        videoURL={
                                          offplanProperty?.video_module
                                            ?.video_url
                                        }
                                        community={
                                          offplanProperty?.community?.title
                                        }
                                        key={offplanProperty.id}
                                        completionYear={
                                          offplanProperty.completion_year
                                        }
                                        price={offplanProperty?.price}
                                        listView={true}
                                        description={
                                          index === 2 || index === 3
                                            ? null
                                            : offplanProperty?.about
                                        }
                                        future_launch={
                                          offplanProperty?.future_launch
                                        }
                                        ggfx_results={
                                          offplanProperty?.ggfx_results
                                        }
                                        strapi_id={offplanProperty?.strapi_id}
                                        property={offplanProperty}
                                        ads_mobile_image={
                                          offplanProperty?.ads_mobile_image
                                            ? offplanProperty?.ads_mobile_image
                                            : ""
                                        }
                                        isAds={
                                          offplanProperty?.ads_image
                                            ? true
                                            : false
                                        }
                                        themeDefault={
                                          index === 2 || index === 3
                                            ? "dark"
                                            : "light"
                                        }
                                      />
                                    </>
                                  )}

                                  {isOffplan ? (
                                    <AdsBannerOff
                                      cardBanner
                                      propertyIndex={index}
                                      listView={offlistView}
                                      propertyBanner={propertyBanner}
                                      searchResult={serverData}
                                    />
                                  ) : (
                                    <AdsBanner
                                      cardBanner
                                      propertyIndex={index}
                                      listView={listView}
                                      propertyBanner={propertyBanner}
                                      isProperty
                                      userObjects={userObjects}
                                      searchResult={serverData}
                                    />
                                  )}
                                </React.Fragment>
                              )
                            })}
                          </div>
                        </div>}
                        {nbPages > page && (
                          <SearchPagination searchResult={serverData} />
                        )}
                      </div>
                      {nbHits !== 0 && (
                        <div className="side-bar-listing-page">
                          <div className="sticky-container">
                            <ProjectSpotlight />
                            <ResidentialPopularSearch
                              dynamicPageData={dynamicData}
                            />
                            <UsefulLInks />
                            <PropertyNegoCardSR team_data={filteredData} />
                            {Object.entries(sidebarProject).length !== 0 && (
                              <SidebarProjects
                                minsBeds={sidebarProject.min_bedrooms}
                                maxBeds={sidebarProject.max_bedrooms}
                                displayBedrooms={
                                  sidebarProject.display_bedrooms
                                }
                                buldingType={sidebarProject.building_type}
                                image={
                                  sidebarProject.ads_image ||
                                  sidebarProject.banner_image ||
                                  sidebarProject.media_images?.[0] ||
                                  sidebarProject.images?.[0] ||
                                  ""
                                }
                                title={sidebarProject.title}
                                developer={sidebarProject.developer}
                                location={sidebarProject.display_address}
                                slug={sidebarProject.slug}
                                videoURL={
                                  sidebarProject.video_module?.video_url
                                }
                                community={sidebarProject.community?.title}
                                key={sidebarProject.id}
                                completionYear={sidebarProject.completion_year}
                                price={sidebarProject.price}
                                listView={true}
                                description={sidebarProject.about?.data}
                                future_launch={sidebarProject.future_launch}
                                ggfx_results={sidebarProject.ggfx_results}
                                strapi_id={sidebarProject.strapi_id}
                                property={sidebarProject}
                                ads_sidebar_image={
                                  sidebarProject?.ads_sidebar_image
                                    ? sidebarProject?.ads_sidebar_image
                                    : ""
                                }
                              />
                            )}
                            {selectedAreas?.length > 0 && (
                              <PropertyAreaguideSR
                                areas={
                                  selectedAreas?.length > 0 ? selectedAreas : []
                                }
                              />
                            )}
                            <Cardsidebar pType={"sale"} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* {nbHits > 0 &&  */}
                  <SRTeamsSlider
                    heading={`Contact Property Expert in ${
                      selectedAreas?.length > 0
                        ? selectedAreas?.map((area) => area.name).join(",")
                        : "Dubai"
                    }`}
                    area={
                      selectedAreas?.length > 0
                        ? selectedAreas
                        : [{ name: "dubai" }]
                    }
                  />
                  {/* } */}
                </>
              )}
            </div>
            {isLoad && (
              <>
                <div className="results-calculator section-m">
                  <Container>
                    <PropertyMortgageCalc
                      pageComp
                      heading="Mortgage Calculator"
                      title="Calculate your monthly mortgage repayments"
                    />
                  </Container>
                </div>
                <Container>
                  <QuestionnaireBannerCom />
                </Container>
                <PropertyCopy
                  pType={pType}
                  buildingType={buildingType}
                  pDepartment={pDepartment}
                  selectedAreas={selectedAreas}
                  isProperty
                />
              </>
            )}
          </>
        }
      </Layout>
    </UserObjectStoreProvider>
  )
  // <SearchResultsPage {...props} />
}

export default SearchResults

export async function getServerData(context) {
  // console.log(context)
  const { params } = context; // Extract dynamic params from context
  // const referer = context.headers.get('referer'); // Use .get for Map objects

  //   const refererPath = new URL(referer).pathname; // Parse the pathname from the referer URL
    // console.log(refererPath); // Outputs: /buy/properties-for-sale/
  const locationParam = params['*']; // Access the wildcard param
  // console.log(locationParam)
  const locationObject = {
    pathname: addTraillingSlash("/buy/"+locationParam) || '', // Ensure it's a string or default to an empty string
  };
  // console.log(locationObject)
  const pageUrlData = parseResultsUrl(locationObject)
  // console.log(pageUrlData)
  const filterQuery = generateFilters({ ...pageUrlData })
  // console.log(filterQuery)
  try {
    const url = "https://providentestate-properties.q.starberry.com/api/search"

    const headers = {
      "Accept-Encoding": "gzip, deflate",
      "Content-Type": "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjYsImVtYWlsIjoidmlqYXlhYmFza2FyQHN0YXJiZXJyeS50diIsImlhdCI6MTczNjk1MTQyNH0.F7nw-iPkDkKryHLVH44o2yIDaUEYpRXMd3SAjS0b5tU",
    }

    const sortQuery = pageUrlData?.sortVal === "prod_properties_price_desc" ? { price: "desc" } : pageUrlData?.sortVal === "prod_properties_price_asc" ? { price: "asc" } : { modified_on:  "desc" }

    const body = {
      fields: ["id"],
      extra_fields: ["algolia_data"],
      algolia_data: true, // if true, it skips fields, extra_fields params and returns strapi algolia_data field alone
      // conditions: {
      //   search_type: "sales",
      //   department: "residential",
      //   status: "For Sale",
      //   publish: true,
      // },
      filters: filterQuery, 
      sort: sortQuery,
      page: pageUrlData?.pageVal,
      limit: pageUrlData.layoutVal === "map" ? "1000" : "10",
    }

    const res = await fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body),
    })

    if (!res.ok) {
      throw new Error(`Response failed`)
    }

    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {
        hits: [],
        hitsPerPage: 10, 
        nbHits: 0, 
        nbPages: 0,
        page: 0,
        status: true
      },
    }
  }
}
