import GGFXImage from "@components/elements/GGFXImage";
import React from "react";

const CustomSlide = ({ data, dataIndex, slideIndex, isCenterSlide, swipeTo, imagename, ggfx_results, strapiID}) => {
  const { image } = data[dataIndex];
  const handleClick = () => {
    swipeTo(slideIndex);
  };
  return (
    <div className={`slide ${isCenterSlide ? "center-slide" : ""}`} onClick={handleClick}>
      <GGFXImage
            ImageSrc={image}
            altText={`Slide ${dataIndex}`}
            imagetransforms={ggfx_results}
            renderer="srcSet"
            imagename={imagename ? imagename : "page.advanced_slider.slide_image"}
            strapiID={strapiID}
          />
    </div>
  );
};

export default React.memo(CustomSlide);
