import * as React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"
import Layout from "@components/Layout/Layout"
import Seo from "../components/seo"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import CTALink from "@components/elements/CTALink"
import { capitalize, isEmpty } from "lodash"
import { generatePropertyURL, offplanResultsURL, propertiesRentURL, propertiesSaleURL, propertyDetailRentURL, propertyDetailSaleURL } from "@lib/urls"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import { resiPropertyTypeOptions } from "@components/SearchResults/SearchFilters/filterOptions"
import { parseHTML, slugToTitle } from "@lib/utils"
import Pagination from "@components/Pagination/Pagination"
import usePagination from "@hooks/usePagination"
import GGFXImage from "@components/elements/GGFXImage"
import areas from './../../static/areas.json';
import offplanAreas from './../../static/offplan_areas.json';
import BannerMenu from "@components/Banner/BannerMenu"
const Sitemap = () => {
//   const data = useStaticQuery(graphql`
//   query {
//     strapiGlobalMenu {
//       header_links {
//         id
//         # content_cta {
//         #   ...contentCtaFragment
//         # }
//         main_menu {
//           ...ctaFragment
//         }
//         sub_menu_cta {
//           ...ctaFragment
//         }
//       }
//     }
//     strapiSitemapPage {
//       image {
//         url
//       }
//       title
//       content {
//         data {
//           content
//         }
//       }
//               ggfx_results {
//         ...STRAPI_GGFX_RESULTFragment
//       }
//       strapi_id
//       add {
//         id
//         # content_cta {
//         #   ...contentCtaFragment
//         # }
//         main_menu {
//           ...ctaFragment
//         }
//         sub_menu_cta {
//             ...ctaFragment
//           }
//       }
//     }
//     allProjects(
//       filter: { publish: { eq: true } }
//       sort: { fields: createdAt, order: DESC }
//     ) {
//       nodes {
//         ...offplanCardFragment
//       }
//     }
   
//     allStrapiPage(filter: {publish: {eq: true}}) {
//       nodes {
//         page_name
//         menu {
//           slug
//           strapi_parent {
//             slug
//           }
//         }
//       }
//     }
//     sales: allProperties(
//     filter: {search_type: {eq: "sales"}, publish: { eq: true }}
//     sort: { fields: createdAt, order: DESC }
//     ) {
//       nodes {
//         slug
//         strapi_id
//         search_type
//       }
//     }
//     lettings: allProperties(
//     filter: {search_type: {eq: "lettings"}, publish: { eq: true }}
//     sort: { fields: createdAt, order: DESC }
//     ) {
//       nodes {
//         slug
//         strapi_id
//         search_type
//       }
//     }
//     allStrapiTeam(
//       filter: { publish: { eq: true }, show_on_website: {eq: true} }
//       sort: { fields: rank, order: ASC }
//     ) {
//       nodes {
//         slug
//         name
//       }
//     }
//     allStrapiOffice(filter: { publish: { eq: true } }) {
//       nodes {
//         title
//         slug
//       }
//     }

//     allStrapiAreaGuide(
//       filter: { publish: { eq: true } }
//       sort: { fields: rank, order: ASC }
//     ) {
//       nodes {
//         slug
//         title
//       }
//     }

//     allStrapiBlog(
//       filter: { publish: { eq: true } }
//       sort: { fields: date, order: DESC }
//     ) {
//       nodes {
//         title
//         slug
//       }
//     }

   
//     allStrapiCareer(filter: { publish: { eq: true } }) {
//       nodes {
//         title
//         slug
//       }
//     }

//   }
// `)
// const { header_links } = data.strapiGlobalMenu

//   const offplans = data?.allProjects?.nodes
//   const buildingTypes = []
//   offplans?.forEach((offplan) => {
//     offplan?.building_type?.forEach((buildingType) => {
//       if (!buildingTypes.includes(buildingType))
//         buildingTypes.push(buildingType)
//     })
//   })

//   const buildingTypeOptions = [
//     {
//       label: "All Types",
//       value: "",
//     },
//     ...buildingTypes.map((buildingType) => ({
//       label: capitalize(slugToTitle(buildingType)),
//       value: buildingType.toLowerCase().replace(/\s/g, "-"),
//     })),
//   ]
//   const [offplanJsondata, setoffplanJsondata] = React.useState([]);

//   const [salesJsondata, setSalesJsondata] = React.useState([]);
//   const [lettingsJsondata, setLettingsJsondata] = React.useState([]);

//   const areaJSON = usePropertyStore((state) => state.areaJSON)
//   const offplanJSON = usePropertyStore((state) => state.offplanJSON)
//   const setAreaJSON = usePropertyStore((state) => state.setAreaJSON)
//   const setOffplanJSON = usePropertyStore((state) => state.setOffplanJSON)
//   React.useEffect(() => {
//     const filtered = offplanAreas.filter(item => item.type !== 'developer');
//     setAreaJSON(areas)
//     setOffplanJSON(filtered)
//     areadatapaginationProps.setCurrentPage(1)
//     teamdatapaginationProps.setCurrentPage(1)
//     blogdatapaginationProps.setCurrentPage(1)
//     projectspaginationProps.setCurrentPage(1)
//     projectsresultspaginationProps.setCurrentPage(1)
//     propertiessalespaginationProps.setCurrentPage(1)
//     propertieslettingspaginationProps.setCurrentPage(1)
//     propertiessaleslistingpaginationProps.setCurrentPage(1)
//     propertieslettingslistingpaginationProps.setCurrentPage(1)
//   }, [])

//   React.useEffect(() => {
//     let letoffplanJsondata = []
//     buildingTypeOptions.forEach((building) => {
//       if (building.value != "") {
//         const buildingURI = offplanResultsURL + "type-" + building.value

//         letoffplanJsondata.push({
//           label: "Off plan " + capitalize(building?.label) + " for sale in Dubai",
//           url: `${buildingURI}/`,

//         }
//         )
//       }
//     })
//     offplanJSON?.forEach((area) => {
//       let uriStr = ""
//       if (area.slug != "") {
//         uriStr = offplanResultsURL + "in-" + area.slug

//         letoffplanJsondata.push({
//           label: "Off plan properties for sale in " + capitalize(area?.slug?.replaceAll(/-/g, " ")),
//           url: `${offplanResultsURL + "in-" + area?.slug + "/"}`,
//         }
//         )

//         buildingTypeOptions.forEach((building) => {
//           if (building.value != "") {
//             const buildingURI = uriStr + "/type-" + building.value

//             letoffplanJsondata.push({
//               label: "Off plan " + capitalize(building?.label) + " for sale in " + capitalize(area?.slug?.replaceAll(/-/g, " ")),
//               url: `${buildingURI}/`,

//             }
//             )
//           }
//         })

//       }
//     })
//     setoffplanJsondata(letoffplanJsondata)

//   }, [offplanJSON])


//   React.useEffect(() => {
//     let letsalesJsondata = []
//     resiPropertyTypeOptions.forEach((building) => {
//       if (building.value != "") {
//         const buildingURI =
//           generatePropertyURL({
//             searchType: "for-sale",
//             buildingType: building.value,
//           })

//         letsalesJsondata.push({
//           label: capitalize(building.label) + " for sale in Dubai",
//           url: `${buildingURI}`,

//         }
//         )
//       }
//     })

//     areaJSON?.forEach((area) => {
//       let uriStr = ""
//       if (area.slug != "") {
//         uriStr =
//           generatePropertyURL({
//             searchType: "for-sale",
//           }) +
//           "in-" +
//           area.slug

//         letsalesJsondata.push({
//           label: "Properties for sale in " + capitalize(area.slug?.replaceAll(/-/g, " ")),
//           url: `${propertiesSaleURL + "in-" + area.slug + "/"}`,
//         }
//         )

//         resiPropertyTypeOptions.forEach((building) => {
//           if (building.value != "") {
//             const buildingURI =
//               generatePropertyURL({
//                 searchType: "for-sale",
//                 buildingType: building.value,
//               }) +
//               "in-" +
//               area.slug

//             letsalesJsondata.push({
//               label: capitalize(building.label) + " for sale in " + capitalize(area.slug?.replaceAll(/-/g, " ")),
//               url: `${buildingURI}/`,

//             }
//             )
//           }
//         })

//       }
//     })
//     setSalesJsondata(letsalesJsondata)

//     let letlettingsJsondata = []
//     resiPropertyTypeOptions.forEach((building) => {
//       if (building.value != "") {
//         const buildingURI =
//           generatePropertyURL({
//             searchType: "for-rent",
//             buildingType: building.value,
//           })

//         letlettingsJsondata.push({
//           label: capitalize(building.label) + " for rent in Dubai",
//           url: `${buildingURI}`,

//         }
//         )
//       }
//     })
//     areaJSON?.forEach((area) => {
//       let uriStr = ""
//       if (area.slug != "") {
//         uriStr =
//           generatePropertyURL({
//             searchType: "for-rent",
//           }) +
//           "in-" +
//           area.slug

//         letlettingsJsondata.push({
//           label: "Properties for rent in " + capitalize(area.slug?.replaceAll(/-/g, " ")),
//           url: `${propertiesRentURL + "in-" + area.slug + "/"}`,
//         }
//         )

//         resiPropertyTypeOptions.forEach((building) => {
//           if (building.value != "") {
//             const buildingURI =
//               generatePropertyURL({
//                 searchType: "for-rent",
//                 buildingType: building.value,
//               }) +
//               "in-" +
//               area.slug

//             letlettingsJsondata.push({
//               label: capitalize(building.label) + " for rent in " + capitalize(area.slug?.replaceAll(/-/g, " ")),
//               url: `${buildingURI}/`,

//             }
//             )
//           }
//         })

//       }
//     })
//     setLettingsJsondata(letlettingsJsondata)

//   }, [areaJSON])
//   const { add, title, content, image, strapi_id, ggfx_results } = data.strapiSitemapPage
//   const lettings = data.lettings.nodes
//   const sales = data.sales.nodes
//   const blogdata = data?.allStrapiBlog?.nodes
//   const teamdata = data?.allStrapiTeam?.nodes
//   const areadata = data?.allStrapiAreaGuide?.nodes
//   const officedata = data?.allStrapiOffice?.nodes
//   const offplandata = data?.allProjects?.nodes
//   const careersdata = data?.allStrapiCareer?.nodes
//   const pagesdata = data?.allStrapiPage?.nodes

//   const breadcrumbURLs = [
//     {
//       url: "",
//       label: "Sitemap",
//     },
//   ]

//   const blogdatapaginationProps = usePagination({
//     items: blogdata,
//     itemsPerPage: 30,
//   })
//   const teamdatapaginationProps = usePagination({
//     items: teamdata,
//     itemsPerPage: 30,
//   })
//   const areadatapaginationProps = usePagination({
//     items: areadata,
//     itemsPerPage: 30,
//   })
//   const projectspaginationProps = usePagination({
//     items: offplandata,
//     itemsPerPage: 30,
//   })
//   const projectsresultspaginationProps = usePagination({
//     items: offplanJsondata,
//     itemsPerPage: 30,
//   })
//   const propertiessalespaginationProps = usePagination({
//     items: sales,
//     itemsPerPage: 30,
//   })
//   const propertieslettingspaginationProps = usePagination({
//     items: lettings,
//     itemsPerPage: 30,
//   })
//   const propertiessaleslistingpaginationProps = usePagination({
//     items: salesJsondata,
//     itemsPerPage: 30,
//   })
//   const propertieslettingslistingpaginationProps = usePagination({
//     items: lettingsJsondata,
//     itemsPerPage: 30,
//   })

  return (
    <Layout
      pageData={{
        page_class: "sitemap-page-wrap",
        layout: "sitemap",
      }}
    >
     
    </Layout>
  )
}

export const Head = () => <Seo customCanonical="https://www.providentestate.com/sitemap/" title="Sitemap" />

export default Sitemap
